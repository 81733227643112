import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../user.service';
import { User } from '../../user.class';
import { IUserRequestOptions } from '../../user.request';

@Component({
  selector: 'plugin-user-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-view.plugin.html',
  styleUrls: ['./user-view.plugin.scss']
})
export class UserViewPlugin {
  private _options: IUserRequestOptions = {};
  @Input()
  set options(value: IUserRequestOptions) {
    this._options = value;
    this.load();
  }
  get options(): IUserRequestOptions {
    return this._options;
  };

  user: User | null = null;

  constructor(
    readonly userService: UserService,
  ) {}

  load(): void {
    if (this.options.username) {
      this.userService.get(this.options.username, this.options).subscribe(user => {
        this.user = user;
      });
    }
  }

}
