import { Injectable } from '@angular/core';
import { ITableDto, ITableRequestOptions } from './table.dto';
import { Table } from './table.class';
import { catchError, map, Observable } from 'rxjs';
import { UserService } from '../../user/user/user.service';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '../../api.service';


@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(
    private apiService: ApiService,
  ) { }

  get(
    id: number,
    options: ITableRequestOptions = {},
  ): Observable<Table | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.tableGet}/${id}`;
    let params = new HttpParams();

    // Set any additional parameters if needed
    // if (options.someOption) {
    //   params = params.set('someOptionKey', 'someOptionValue');
    // }

    return this.apiService.get<ITableDto>(url, { params }).pipe(
      map(dto => {
        if (dto) {
          return new Table(dto);
        }
        return null;
      }),
      catchError(this.apiService.handleError.bind(this))
    );
  }

  list(options: ITableRequestOptions): Observable<Table[] | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.tableList}`;
    let params = new HttpParams();

    if (options.loadSeats) {
      params = params.set('sts', '1');
    }

    return this.apiService.get<ITableDto[]>(url, { params }).pipe(
      map(dtos => {
        console.log('Received dtos:', dtos);
        return dtos.map(dto => new Table(dto));
      }),
      catchError(this.apiService.handleError.bind(this))
    );
  }

  save(table: Table): Observable<Table | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.tableSave}`;
    
    return this.apiService.post<ITableDto>(url, table.getDto()).pipe(
      map(dto => {
        if (dto) {
          return new Table(dto);
        }
        return null;
      }),
      catchError(this.apiService.handleError.bind(this))
    );
  }
}
