import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IUserRoleRequestOptions } from '../../userrole.request';
import { UserRoleService } from '../../userrole.service';

@Component({
  selector: 'plugin-user-role-picker',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-role-picker.plugin.html',
  styleUrls: ['./user-role-picker.plugin.scss']
})
export class UserRolePickerPlugin {
  private _options: IUserRoleRequestOptions = {};
  @Input()
  set options(value: IUserRoleRequestOptions) {
    this._options = value;
    this.load();
  }
  get options(): IUserRoleRequestOptions {
    return this._options;
  }

  @Output() itemSelected: EventEmitter<string> = new EventEmitter();

  list: string[] = [];
  selectedRole: string = '';

  constructor(
    private userRoleService: UserRoleService,
  ) {}

  load() {
    this.loadFromAPI();
  }

  loadFromAPI() {
    this.userRoleService.list(this.options).subscribe(l => {
      if (l) {
        this.list = l;
      } else {
        this.list = [];
      }
      this.selectedRole = '';
    })
  }

  selectRole(item: string) {
    this.selectedRole = item;
    this.itemSelected.emit(item);
  }
}
