import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '../../user/user.service';
import { ILoginRequestDto } from '../../user/userLoginDto';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConnectionService } from '../connection.service';
import { Router } from '@angular/router';

@Component({
  selector: 'modal-user-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './login.modal.html',
  styleUrls: ['./login.modal.scss']
})
export class UserLoginModal {
  loginForm: FormGroup;

  constructor(
    public modalRef: BsModalRef, 
    private userService: UserService,
    private connectionService: ConnectionService,
    private router: Router,
  ) {
    this.loginForm = new FormGroup({
      'username': new FormControl(null, [Validators.required, Validators.pattern(/^\S*$/)]),
      'password': new FormControl(null, [Validators.required, Validators.pattern(/^\S*$/)])
    });
  }

  onConnect() {
    if (this.loginForm.valid) {
      const { username, password } = this.loginForm.value;
      const dto: ILoginRequestDto = { 
        username: username, 
        pwdc: password,
      };

      this.userService.login(dto).subscribe(
        response => {
          this.connectionService.handleLoginResponse(response);
          this.modalRef.hide();
        },
        error => {
          this.router.navigate(['not-authorized']);
          this.modalRef.hide();
        }
      );
    }
  }

  onCancel() {
    this.loginForm.reset();
    this.modalRef.hide();
  }
}
