import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ErrorHandlingService } from './error-handling.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient, 
    private errorHandlingService: ErrorHandlingService,
  ) {}

  patch<T>(url: string, body: any): Observable<T> {
    return this.http.patch<T>(url, body, this.getAuthHeaders());
  }
  
  get<T>(url: string, options?: { params?: HttpParams }): Observable<T> {
    return this.http.get<T>(url, { ...this.getAuthHeaders(), ...options });
  }
    
  post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(url, body, this.getAuthHeaders());
  }

  put<T>(url: string, body: any, options?: { params?: HttpParams }): Observable<T> {
    return this.http.put<T>(url, body, { ...this.getAuthHeaders(), ...options }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  delete<T>(url: string, options?: { params?: HttpParams }): Observable<T> {
    return this.http.delete<T>(url, { ...this.getAuthHeaders(), ...options }).pipe(
      catchError(this.handleError.bind(this))
    );
  }
  
  private getAuthHeaders(): { headers: HttpHeaders } {
    const token = this.getToken(); // Fonction pour récupérer le token JWT
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      })
    };
  }

  public handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      this.errorHandlingService.handleAuthError();
    }
    return throwError(error);
  }

  private getToken(): string | null {
    return localStorage.getItem('authToken');
  }
}
