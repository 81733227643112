import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Seat } from '../../seat.class';
import { ISeatDto } from '../../seat.dto';
import { ConnectionService } from '../../../../user/connect/connection.service';
import { ERoleCodes } from 'src/app/user/role/role.enum';

@Component({
  selector: 'plugin-seat-inline-state',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './seat-inline-state.plugin.html',
  styleUrls: ['./seat-inline-state.plugin.scss']
})
export class SeatInlineStatePlugin implements OnInit {
  private _dto: ISeatDto = {};
  @Input()
  set seatDto(value: ISeatDto) {
    this._dto = value;
    this.load();
  };
  get seatDto() {
    return this._dto;
  }

  @Output() bookedSeat: EventEmitter<void> = new EventEmitter(); 
  @Output() releasedSeat: EventEmitter<ISeatDto> = new EventEmitter(); 

  username$ = this.connectionService.username$;
  viewAll: boolean = false;
  roles$ = this.connectionService.roles$;
  
  constructor(
    private connectionService: ConnectionService,
  ) {}

  ngOnInit(): void {
    this.connectionService.roles$.subscribe(roles => {
      // Vérifie si l'utilisateur a l'un des rôles suivants : SUPAR, ADMIN ou GEST
      this.viewAll = roles.includes(ERoleCodes.SUPER) || roles.includes(ERoleCodes.ADMIN) || roles.includes(ERoleCodes.GEST);
    });
  }
  seat: Seat = new Seat();

  load() {
    this.seat = new Seat(this.seatDto);
  }

  reserveSeat() {
    this.bookedSeat.emit();
  }

  releaseSeat() {
    this.releasedSeat.emit(this.seat.getDto());
  }
}
