import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPickerPlugin } from "../../plugin/picker/user-picker.plugin";
import { IUserDto } from '../../user.dto';
import { User } from '../../user.class';
import { Router } from '@angular/router';
import { IUserRequestOptions } from '../../user.request';
import { UserService } from '../../user.service';

@Component({
  standalone: true,
  imports: [
    CommonModule, 
    UserPickerPlugin
  ],
  templateUrl: './user-list.route.html',
  styleUrls: ['./user-list.route.scss']
})
export class UserListRoute {
  options: IUserRequestOptions = {};

  constructor(
    private router: Router,
    private userService: UserService,
  ) {} 

  editUser(dto: IUserDto) {
    const user: User = new User(dto);
    this.router.navigate(['/user/edit', user.username], { queryParams: { m: 0 } });
  }

  newPassword(dto: IUserDto) {
    const user: User = new User(dto);
    this.router.navigate(['/user/password', user.username]);
  }
}
