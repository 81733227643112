/*
  _   _                               ____                   _                          
 | \ | |   __ _  __   __             |  _ \    __ _   _ __  | |_    __ _    __ _    ___ 
 |  \| |  / _` | \ \ / /    _____    | |_) |  / _` | | '__| | __|  / _` |  / _` |  / _ \
 | |\  | | (_| |  \ V /    |_____|   |  __/  | (_| | | |    | |_  | (_| | | (_| | |  __/
 |_| \_|  \__,_|   \_/               |_|      \__,_| |_|     \__|  \__,_|  \__, |  \___|
                                                                           |___/        
*/

export const NAV_STORE_KEY = 'navStore';

export enum ETopicIds {
  Topic_Tables = 'TOPICTABLES',  
  Topic_Seats = 'TOPICSEATS',
  Topic_Gestion = 'GESTION',  
  Topic_Admin = 'TOPICADMIN',
}

export enum ESectionIds {
  Section_Tables = 'SECTION_TABLES',
  Section_TableList = 'SECTION_TABLELIST',
  Section_TableNew = 'SECTION_TABLE_NEW',
  Section_SeatsAvailable = 'SECTION_SEATSAVAILABLE',
  Section_MySeats = 'SECTION_MYSEATS',
  Section_Buyers = 'SECTION_BUYERS',
  Section_SeatsFullList = 'SECTION_SEATSFULLLIST',
  Section_Activation = 'SECTION_ACTIVATION',
  Section_Accounts = 'SECTION_ACCOUNTS',
  Section_CreateUser = 'SECTION_CREATEUSER',
}   

export interface INavItem {
  id: ETopicIds | ESectionIds;
  label: string;
  labelClass?: string;
  labelStyles?: { [key: string]: string };   
  subLabel?: string;
  subLabelClass?: string;
  subLabelStyles?: { [key: string]: string };
  parentId?: string;                              // pour une section, c'est un topic
  route?: string,
  rolesAllowed?: string[],
  rolesDenied?: string[],
}
