import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConnectionService } from '../connect/connection.service';

@Component({
  selector: 'plugin-user-face',
  templateUrl: './user-face.plugin.html',
  styleUrls: ['./user-face.plugin.scss']
})
export class UserFacePlugin implements OnInit {
  username$ = this.connectionService.username$;

  constructor(
    private connectionService: ConnectionService,
  ) {}

  ngOnInit(): void {}

}
