<div *ngIf="user; else noUser">
    <h2>Edit User</h2>
    <form [formGroup]="userForm">
      <div>
        <label for="username">Username:</label>
        <input id="username" formControlName="username" readonly />
      </div>
      <div>
        <label for="email">Email:</label>
        <input id="email" formControlName="email" type="email" required />
      </div>
      <div>
        <label for="isActive">Active:</label>
        <input id="isActive" formControlName="isActive" type="checkbox" />
      </div>
      <div>
        <label for="dtStart">Start Date:</label>
        <input id="dtStart" formControlName="dtStart" type="date" />
      </div>
      <div>
        <label for="dtEnd">End Date:</label>
        <input id="dtEnd" formControlName="dtEnd" type="date" />
      </div>
      <div>
        <button type="button" (click)="save()">Sauvegarder</button>
        <button type="button" (click)="cancel()">Annuler</button>
      </div>
    </form>
  </div>
  <ng-template #noUser>
    <p>No user selected.</p>
  </ng-template>
  