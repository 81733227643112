import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { UserService } from '../../user.service';

@Component({
  selector: 'plugin-password-new',
  standalone: true,
  imports: [CommonModule, FormsModule], // Include FormsModule here
  templateUrl: './new-password.plugin.html',
  styleUrls: ['./new-password.plugin.scss']
})
export class NewPasswordPlugin {
  @Input() username: string = '';

  password: string = '';
  confirmPassword: string = '';
  passwordMismatch: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  validatePasswords() {
    this.passwordMismatch = this.password !== this.confirmPassword;
  }

  submitPassword() {
    this.validatePasswords();

    if (!this.passwordMismatch) {
      const encryptedPassword = CryptoJS.MD5(this.password).toString();
      console.log('Password to send (MD5 encrypted):', encryptedPassword);

      this.userService.newPassword(
        this.username, 
        encryptedPassword,      // deprecated
        this.password,          // new
      ).subscribe(u => {
        if (u) {
          this.router.navigate(['/user/list']);
        } else {
          console.error('Erreur réinit mdp.');
        }
      })
    }
  }

  cancel() {
    this.router.navigate(['/user/list']);
  }
}
