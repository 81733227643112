import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRolePickerPlugin } from '../picker/user-role-picker.plugin';
import { IUserRoleRequestOptions } from '../../userrole.request';
import { UserRoleService } from '../../userrole.service';

@Component({
  selector: 'plugin-user-role-mapper',
  standalone: true,
  imports: [
    CommonModule, 
    UserRolePickerPlugin,
  ],
  templateUrl: './user-role-mapper.plugin.html',
  styleUrls: ['./user-role-mapper.plugin.scss']
})
export class UserRoleMapperPlugin {
  private _username: string = '';
  @Input()
  set username(value: string) {
    this._username = value;
    this.load();
  }
  get username(): string {
    return this._username;
  }

  availableUserRolesOptions: IUserRoleRequestOptions = {};  // Options for available roles
  assignedRUserolesOptions: IUserRoleRequestOptions = {};   // Options for assigned roles

  constructor(
    private userRoleService: UserRoleService,
  ) {}

  assignedRoleSelected: string = '';
  availableRoleSelected: string = '';

  load() {
    console.log(this.username);
    // configuration des pickers
    this.availableUserRolesOptions = {
      username: this.username,
      available: true,
    }
    this.assignedRUserolesOptions = {
      username: this.username,
      assigned: true,
    }
    this.assignedRoleSelected = '';
    this.availableRoleSelected = '';
  }

  selectAvailableItem(item: string) {
    this.availableRoleSelected = item;
  }
  selectAssignedItem(item: string) {
    this.assignedRoleSelected = item;
  }

  assignRole() {
    console.log('Assign role', this.availableRoleSelected);
    this.userRoleService.assignRole({
      username: this.username,
      roleCode: this.availableRoleSelected,
    }).subscribe(dto => {
      this.load();
    });
  }

  removeRole() {
    console.log('Remove role', this.assignedRoleSelected);
    this.userRoleService.removeRole({
      username: this.username,
      roleCode: this.assignedRoleSelected,
    }).subscribe(dto => {
      console.log('user mis à jour', dto);
      this.load();
    });
  }
}
