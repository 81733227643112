import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  templateUrl: './seatingplan.route.html',
  styleUrls: ['./seatingplan.route.scss']
})
export class SeatingPlanRoute implements OnInit {

  constructor(private store: Store) {}

  ngOnInit():void {}

  ngOnDestroy(): void {}
}
