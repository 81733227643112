<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="burger menu">
  <mat-icon class="burger-button">more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngIf="isLoggedIn$ | async; else notLoggedIn">
    <button mat-menu-item (click)="Deconnecter()">
      <span>Se déconnecter</span>
    </button>
  </ng-container>
  <ng-template #notLoggedIn>
    <button mat-menu-item (click)="Connecter($event)">
      <span>Se connecter</span>
    </button>
  </ng-template>
  <ng-container *ngIf="isLoggedIn$ | async;">
    <button mat-menu-item (click)="VoirProfil($event)">
      <span>Voir le profil</span>
    </button>
  </ng-container>
</mat-menu>
