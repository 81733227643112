import { Action }                           from "@ngrx/store";
import { DisplayError, DisplayMessage, 
          NavActions, NavActionsTypes, 
          SelectSection, SelectTopic }      from "./nav.actions";
import { INavState }                        from "./nav.state";
import { initialNavState }                  from './nav.state';


export function navReducer(state: INavState = initialNavState, action: Action): INavState {
    switch (action.type) {
      case NavActionsTypes.TOOGLE_AUX_PANEL:
        return { 
          ...state, 
          showAuxPanel: !state.showAuxPanel 
        };
  
      case NavActionsTypes.SELECT_TOPIC:
        const { newTopic } = (action as SelectTopic).payload;
        return {
          ...state,
          idTopic: newTopic
        };
  
      case NavActionsTypes.SELECT_SECTION:
        const { newSection } = (action as SelectSection).payload;
        return {
          ...state,
          idSection: newSection
        };
      
      case NavActionsTypes.DISPLAY_MESSAGE:
        const { infoMessage } = (action as DisplayMessage).payload;
        return {
          ...state,
          infoMessage: infoMessage
        }

      case NavActionsTypes.DISPLAY_ERROR:
        const { errorMessage } = (action as DisplayError).payload;
        return {
          ...state,
          errorMessage: errorMessage
        }

      default:
        return state;
    }
}

  