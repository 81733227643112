import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IBuyerRequestOptions } from '../../buyer.request';
import { Buyer } from '../../buyer.class';
import { BuyerService } from '../../buyer.service';
import { BuyerInlineEditPlugin } from '../inline-edit/buyer-inline-edit.plugin';
import { IBuyerDto } from '../../buyer.dto';

@Component({
  selector: 'plugin-buyer-account-list',
  standalone: true,
  imports: [
    CommonModule,
    BuyerInlineEditPlugin,
  ],
  templateUrl: './buyer-account-list.plugin.html',
  styleUrls: ['./buyer-account-list.plugin.scss']
})
export class BuyerAccountListPlugin implements OnInit {
  private _options: IBuyerRequestOptions = {};
  @Input()
  set options(value: IBuyerRequestOptions) {
    this._options = value;
    this.load();
  }
  get options(): IBuyerRequestOptions {
    return this._options;
  }

  list: Buyer[] = [];

  constructor(
    private buyerService: BuyerService,
  ) {}

  ngOnInit(): void {
      this.load();
  }

  load() {
    this.options.loadUsers = true;
    this.options.loadSeats = true;
    this.buyerService.list(this.options).subscribe(l => {
      console.log('accounts', l);
      if (l) {
        this.list = l;
      } else {
        console.error('error loading buyers');
      }
    }, error => {
      console.error('error loading buyers');
    })
  }

  handleNewValue(buyerDto: IBuyerDto) {
    const buyer: Buyer = new Buyer(buyerDto);
    this.buyerService.setSeatCount(buyer).subscribe(updatedBuyer => {
      console.log(updatedBuyer);
      if (updatedBuyer) {
        const index = this.list.findIndex(b => b.id === updatedBuyer.id);
        if (index !== -1) {
          this.list[index] = updatedBuyer;
        } else {
          this.list.push(updatedBuyer);
        }
      } else {
        console.error('Error updating buyer');
      }
    }, error => {
      console.error('Error updating buyer', error);
    });
  }
}
