import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserEditPlugin } from "../../plugin/edit/user-edit.plugin";
import { UserViewPlugin } from "../../plugin/view/user-view.plugin";
import { combineLatest } from 'rxjs';
import { IUserDto } from '../../user.dto';
import { User } from '../../user.class';
import { UserService } from '../../user.service';
import { IUserRequestOptions } from '../../user.request';
import { UserRoleMapperPlugin } from '../../../role/plugin/mapper/user-role-mapper.plugin';

@Component({
  standalone: true,
  imports: [
    CommonModule, 
    UserEditPlugin, 
    UserViewPlugin,
    UserRoleMapperPlugin,
  ],
  templateUrl: './user-edit.route.html',
  styleUrls: ['./user-edit.route.scss']
})
export class UserEditRoute implements OnInit {
  username: string = '';
  editMode: boolean = false;
  queryParams: { [key: string]: string | null } = {}; 

  options: IUserRequestOptions = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    // Combiner les observables paramMap et queryParamMap
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([paramMap, queryParamMap]) => {
      // Récupérer le paramètre de route
      this.username = paramMap.get('username') || '';

      // Récupérer les paramètres de requête
      this.queryParams = queryParamMap.keys.reduce((acc: { [key: string]: string | null }, key) => {
        acc[key] = queryParamMap.get(key);
        return acc;
      }, {});
      const modeParam = this.queryParams['m'];
      this.editMode = modeParam ? modeParam === '1' : false;

      const newOptions: IUserRequestOptions = {
        username: this.username,     
      }
      this.options = newOptions;
    });
  }

  enterEditMode(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { m: 1 },
      queryParamsHandling: 'merge'
    });
  }
  enterViewMode(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { m: 0 },
      queryParamsHandling: 'merge'
    });
  }

  updateSubmitted(dto: IUserDto) {
    const user: User = new User(dto);
    this.userService.save(user).subscribe(u => {
      if (!u) {
        console.error('sauvegarde ko');
      } else {
        // nav vers liste
        this.router.navigate(['/user/list']);
      }
    });
  }
  
  cancel() {
    this.router.navigate(['/user/list']);
  }
}
