<table class="picker-table">
  <thead>
    <tr>
      <th>Nom table</th>
      <th>Sièges</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of list" 
        [class.selected]="item.id === selectedItem.id"
        (click)="selectItem(item)" 
        (dblclick)="activateItem(item)">
      <td>{{ item.name }}</td>
      <td>{{ item.seats?.length }}</td>
      <td class="actions">
        <a href="#" (click)="viewItem($event, item)">Voir</a>
      </td>
    </tr>
  </tbody>
</table>
