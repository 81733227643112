import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableListManagerPlugin } from '../../plugin/list-manager/table-list-manager.plugin';
import { ITableRequestOptions } from '../../table.dto';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TableListManagerPlugin,
  ],
  templateUrl: './table-list-manager.route.html',
  styleUrls: ['./table-list-manager.route.scss']
})
export class TableListManagerRoute implements OnInit {
  options: ITableRequestOptions = {};

  constructor() {}

  ngOnInit(): void {
      this.options = {};
  }


}
