import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserListRoute } from './user/route/list/user-list.route';
import { AdminGuard, AuthGuard } from '../auth.guard';
import { UserEditRoute } from './user/route/edit/user-edit.route';
import { BuyerActivationListRoute } from './buyer/route/activation-list/buyer-activation-list.route';
import { BuyerAccountListRoute } from './buyer/route/account-list/buyer-account-list.route';
import { UserCreateRoute } from './user/route/create/user-create.route';
import { UserPasswordRoute } from './user/route/password/password.route';
import { ProfileRoute } from './user/route/profile/profile.route';

const routes: Routes = [
  { 
    path: 'profile', 
    component: ProfileRoute,
    canActivate: [AuthGuard],
  },    
  { 
    path: 'list', 
    component: UserListRoute,
    canActivate: [AdminGuard],
  },  
  { 
    path: 'create', 
    component: UserCreateRoute,
    canActivate: [AdminGuard],
  },  
  { 
    path: 'password/:username', 
    component: UserPasswordRoute,
    canActivate: [AdminGuard]
  },  
  { 
    path: 'buyer/activation', 
    component: BuyerActivationListRoute,
    canActivate: [AdminGuard]
  },
  { 
    path: 'edit/:username', 
    component: UserEditRoute,
    canActivate: [AdminGuard]
  },
  { 
    path: 'user/buyer/account', 
    component: BuyerAccountListRoute,
    canActivate: []
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule {}