<div *ngIf="item; else noItem">
  <table class="item-details-table">
    <tr>
      <th>Nom de la table:</th>
      <td>{{ item.name }}</td>
    </tr>
  </table>
</div>
<ng-template #noItem>
  <p>No item selected.</p>
</ng-template>
