import { Table } from "../table/table.class";
import { ISeatDto } from "./seat.dto";
import { Buyer } from '../../user/buyer/buyer.class';

export class Seat {
    id: number = 0;
    label: string = '';
    idTable: number = 0;
    idBuyer: number = 0;
    bookingName: string = '';

    table?: Table;
    buyer?: Buyer;

    get buyerUsername(): string {
        return this.buyer?.user?.username || '';
    }

    constructor(
        dto?: ISeatDto,
    ) {
        if (dto) {
            this.loadDto(dto);
        }
    }

    loadDto(dto: ISeatDto) {
        this.id = dto.idSeat ? parseInt(dto.idSeat.toString(), 10) : 0;
        this.idTable = dto.idTable ? parseInt(dto.idTable.toString(), 10) : 0;
        this.idBuyer = dto.idBuyer ? parseInt(dto.idBuyer.toString(), 10) : 0;
        this.label = dto.label ? dto.label : '';
        this.bookingName = dto.bookingName ? dto.bookingName : '';
        this.buyer = dto.buyer ? new Buyer(dto.buyer) : undefined;
        this.table = dto.table ? new Table(dto.table) : undefined;
    }

    getDto(): ISeatDto {
        const dto: ISeatDto = {
            idSeat: this.id !== 0 ? this.id : undefined,
            idTable: this.idTable !== 0 ? this.idTable : undefined,
            idBuyer: this.idBuyer !== 0 ? this.idBuyer : undefined,
            bookingName: this.bookingName,
            label: this.label,
            table: this.table ? this.table.getDto() : undefined,
            buyer: this.buyer ? this.buyer.getDto() : undefined,
        }
        return dto;
    }

}






// }
