import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISeatDto } from '../../seat.dto';
import { Seat } from '../../seat.class';
import { SeatService } from '../../seat.service';
import { ISeatRequestOptions } from '../../seat.request';

@Component({
  selector: 'plugin-seat-picker',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './seat-picker.plugin.html',
  styleUrls: ['./seat-picker.plugin.scss']
})
export class SeatPickerPlugin {
  private _options: ISeatRequestOptions = {};
  @Input()
  set options(value: ISeatRequestOptions) {
    console.log('pivker !');
    this._options = value;
    this.load();
  }
  get options(): ISeatRequestOptions {
    return this._options;
  }

  // output
  @Output() selected: EventEmitter<ISeatDto> = new EventEmitter();    // simple clic
  @Output() activated: EventEmitter<ISeatDto> = new EventEmitter();   // double-click
  @Output() viewSeats: EventEmitter<ISeatDto> = new EventEmitter();   

  list: Seat[] = [];

  _selectedItem: Seat = new Seat();
  set selectedItem(value: Seat) {
    this._selectedItem = value;
  }
  get selectedItem(): Seat {
    return this._selectedItem;
  }
  
  constructor(
    private seatService: SeatService,
  ) {}

  load() {
    this.options.loadTable = true;    // on a besoin des données tables
    this.options.loadBuyer = true;    // idem
    this.seatService.list(this.options).subscribe(data => {
      if (data) {
        this.list = data;
      } else {
        console.log('No data received');
      }
    }, error => {
      console.error('Error loading data:', error);
    });
  }
  

  selectItem(item: Seat) {
    this.selectedItem = item;
    this.selected.emit(this.selectedItem.getDto());
  }

  activateItem(item: Seat) {
    this.selectedItem = item;
    this.selected.emit(this.selectedItem.getDto());
  }

  viewItem(event: Event, item: Seat) {
    event.preventDefault();
    this.selectedItem = item; //new Table(dto);
    this.activated.emit(this.selectedItem.getDto());
  }

}
