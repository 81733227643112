import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { User } from '../../user.class';
import { UserService } from '../../user.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'plugin-user-create',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './create.plugin.html',
  styleUrls: ['./create.plugin.scss']
})
export class UserCreatePlugin {
  user: User = new User();
  confirmPassword: string = '';

  constructor(
    private userService: UserService,
  ) {}

  canSubmit(): boolean {
    return (
      this.user.username.trim() !== '' &&
      this.user.email.trim() !== '' &&
      this.user.password_deprecated.trim() !== '' &&
      this.user.password_deprecated === this.confirmPassword
    );
  }

  createUser() {
    console.log('création');
    if (this.canSubmit()) {
      this.user.clrPassword = this.user.password_deprecated; // new
      const hashedPassword = CryptoJS.MD5(this.user.password_deprecated).toString();
      this.user.password_deprecated = hashedPassword;  // deprecated
      console.log('user à créer', this.user);
      
      this.userService.create(this.user).subscribe(result => {
        console.log('User created successfully:', result);
        // Reset the form after successful creation
        this.user = new User();
        this.confirmPassword = '';
      }, error => {
        console.error('Error creating user:', error);
      });
    }
  }
}
