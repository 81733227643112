import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITableRequestOptions } from '../../table.dto';
import { Table } from '../../table.class';
import { TableService } from '../../table.service';

@Component({
  selector: 'plugin-table-view',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './table-view.plugin.html',
  styleUrls: ['./table-view.plugin.scss']
})
export class TableViewPlugin {
  private _options: ITableRequestOptions = {};
  @Input()
  set options(value: ITableRequestOptions) {
    this._options = value;
    this.load();
  }
  get options(): ITableRequestOptions {
    return this._options;
  };

  item: Table | null = null;

  constructor(
    readonly tableService: TableService,
  ) {}

  load(): void {
    if (this.options.id) {
      this.tableService.get(this.options.id, this.options).subscribe(table => {
        this.item = table;
      });
    }
  }
}
