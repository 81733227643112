<form [formGroup]="loginForm" (ngSubmit)="onConnect()" class="login-form">
    <div class="form-group">
      <label for="username">Identifiant</label>
      <input type="text" id="username" formControlName="username" placeholder="Votre identifiant">
      <div *ngIf="loginForm.get('username')?.invalid && loginForm.get('username')?.touched" class="error-message">
        <div *ngIf="loginForm.get('username')?.errors?.['required']">Identifiant requis.</div>
        <div *ngIf="loginForm.get('username')?.errors?.['pattern']">Identifiant incorrect.</div>
      </div>
    </div>
  
    <div class="form-group">
      <label for="password">Mot de passe</label>
      <input type="password" id="password" formControlName="password" placeholder="Votre mot de passe">
      <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched" class="error-message">
        <div *ngIf="loginForm.get('password')?.errors?.['required']">Mot de passe requis.</div>
        <div *ngIf="loginForm.get('password')?.errors?.['pattern']">Mot de passe incorrect.</div>
      </div>
    </div>
  
    <div class="form-actions">
      <button type="submit" [disabled]="!loginForm.valid" class="btn-primary">Connecter</button>
      <button type="button" (click)="onCancel()" class="btn-secondary">Annuler</button>
    </div>
  </form>
  