import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITableDto, ITableRequestOptions } from '../../table.dto';
import { ActivatedRoute, Router } from '@angular/router';
import { TableService } from '../../table.service';
import { combineLatest } from 'rxjs';
import { TableEditPlugin } from '../../plugin/edit/table-edit.plugin';
import { TableViewPlugin } from '../../plugin/view/table-view.plugin';
import { Table } from '../../table.class';
import { SeatEditPlugin } from '../../../seat/plugin/edit/seat-edit.plugin';
import { ISeatDto } from '../../../seat/seat.dto';
import { Seat } from '../../../seat/seat.class';
import { SeatService } from '../../../seat/seat.service';
import { SeatPickerPlugin } from '../../../seat/plugin/picker/seat-picker.plugin';
import { ISeatRequestOptions } from '../../../seat/seat.request';
import { ConnectionService } from '../../../../user/connect/connection.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TableEditPlugin,
    TableViewPlugin,
    SeatEditPlugin,
    SeatPickerPlugin,
  ],
  templateUrl: './table-edit.route.html',
  styleUrls: ['./table-edit.route.scss']
})
export class TableEditRoute implements OnInit {
  editMode: boolean = false;
  queryParams: { [key: string]: string | null } = {}; 

  // table edit plugin
  tableOptions: ITableRequestOptions = {};
  idTable: number = 0;

  // ajout de siège
  addSeatOptions: ISeatRequestOptions = {};

  // seat picker (liste des sièges)
  seatPickerOptions: ISeatRequestOptions = {};

  isAdminOrSuper: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private TableService: TableService,
    private seatService: SeatService,
    private connectionService: ConnectionService,
  ) {}

  ngOnInit(): void {
    this.isAdminOrSuper = this.connectionService.getRoleCodes().some(role => role === 'ADMIN' || role === 'SUPER');

    // Combiner les observables paramMap et queryParamMap
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([paramMap, queryParamMap]) => {
      console.log('combineLatest subscription triggered');

      // Récupérer le paramètre de route
      this.idTable = parseInt(paramMap.get('idTable') || '0');
      console.log('idTable:', this.idTable);

      // Récupérer les paramètres de requête
      this.queryParams = queryParamMap.keys.reduce((acc: { [key: string]: string | null }, key) => {
        acc[key] = queryParamMap.get(key);
        return acc;
      }, {});
      console.log('queryParams:', this.queryParams);

      const modeParam = this.queryParams['m'];
      this.editMode = modeParam ? modeParam === '1' : false;
      console.log('editMode:', this.editMode);

      // configuration édition table
      const newOptions: ITableRequestOptions = {
        id: this.idTable,    
        loadSeats: true, 
      };
      this.tableOptions = newOptions;
      console.log('tableOptions:', this.tableOptions);

      this.loadSeatPicker();

      // zone d'ajout de sièges
      this.addSeatOptions = { 
        id: 0, 
        idTable: this.idTable,
      };
      console.log('addSeatOptions:', this.addSeatOptions);
    });
  }

  loadSeatPicker() {
    this.seatPickerOptions = {
      tableIds: [this.idTable],
      loadBuyer: true,
      loadTable: true,
      showViewButton: false,
    };
  }

  // passage en mode édition
  enterEditMode(): void {
    console.log('enterEditMode called');
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { m: 1 },
      queryParamsHandling: 'merge'
    });
  }

  // submit depuis le plugin edit
  updateSubmitted(dto: ITableDto) {
    console.log('updateSubmitted called', dto);
    const item: Table = new Table(dto);
    this.TableService.save(item).subscribe(t => {
      if (!t) {
        console.error('sauvegarde ko');
      } else {
        // nav vers liste
        this.router.navigate(['/table/list']);
      }
    });
  }

  // cancel depuis le plugin edit
  cancel() {
    console.log('cancel called');
    this.router.navigate(['/table/list']);
  }

  // submit depuis le plugin d'ajout d'un siège
  submitNewSeat(dto: ISeatDto) {
    console.log('submitNewSeat called', dto);
    const seat: Seat = new Seat(dto);
    this.seatService.save(seat).subscribe(s => {
      if (!s) {
        console.error('sauvegarde ko');
      } else {
        // réinit
        console.log('to do : réinit');
        this.loadSeatPicker();
      }
    });
  }
}
