<div class="seat-status">
  <div *ngIf="seat.idBuyer === 0; else reserved">
    <span>siège libre</span>
    <button *ngIf="(roles$ | async)?.includes('BUYER')" (click)="reserveSeat()">Réserver</button>
  </div>
  <ng-template #reserved>
    <span *ngIf="viewAll || (seat.buyer?.username && (seat.buyer?.username === (username$ | async))); else defaultReserved">
      {{ seat.bookingName }}
    </span>
    <ng-template #defaultReserved>
      <span>siège réservé</span>
    </ng-template>
    <button 
      *ngIf="(seat.buyer?.username === (username$ | async)) || (roles$ | async)?.includes('ADMIN') || (roles$ | async)?.includes('SUPER')" 
      class="release-button" 
      (click)="releaseSeat()">
      Libérer
    </button>
  </ng-template>
</div>
