/*
  ____                  _     _                            ____                                                              _   
 / ___|    ___    ___  | |_  (_)   ___    _ __    ___     / ___|   ___    _ __ ___    _ __     ___    _ __     ___   _ __   | |_ 
 \___ \   / _ \  / __| | __| | |  / _ \  | '_ \  / __|   | |      / _ \  | '_ ` _ \  | '_ \   / _ \  | '_ \   / _ \ | '_ \  | __|
  ___) | |  __/ | (__  | |_  | | | (_) | | | | | \__ \   | |___  | (_) | | | | | | | | |_) | | (_) | | | | | |  __/ | | | | | |_ 
 |____/   \___|  \___|  \__| |_|  \___/  |_| |_| |___/    \____|  \___/  |_| |_| |_| | .__/   \___/  |_| |_|  \___| |_| |_|  \__|
                                                                                     |_|                                         
*/
import { Component, OnInit }                    from '@angular/core';
import { INavItem, ESectionIds, ETopicIds }       from '../nav.share';
import { INavState }                            from '../nav.state';
import { Observable, combineLatest, map, of }   from 'rxjs';
import { Store }                        from '@ngrx/store';
import { SelectSection }                        from '../nav.actions';
import { selectTopicId } from '../nav.selector';
import { ERoleCodes } from '../../user/role/role.enum';
import { ConnectionService }                    from '../../user/connect/connection.service';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit {
  roles$ = this.connectionService.roles$;

  topicId$!: Observable<string>;
  filteredSections$!: Observable<INavItem[]>;
  selectedSectionId?: string;
  selectedTopicId$!: Observable<ETopicIds | ESectionIds>;

  // ------------------------------------------ définition des sections
    sections: INavItem[] = [
      {
        id: ESectionIds.Section_Tables,
        parentId: ETopicIds.Topic_Tables,
        label: 'Plan général',
        subLabel: "",
        subLabelClass: "",
        route: "plan",
      },
      {                 // liste des tables
        id: ESectionIds.Section_TableList,
        parentId: ETopicIds.Topic_Tables,
        label: 'Tables',
        subLabel: "",
        subLabelClass: "",
        route: "room/table/list",
        rolesAllowed: [
          ERoleCodes.ADMIN,
          ERoleCodes.SUPER,
          ERoleCodes.BUYER,
          ERoleCodes.GEST,
        ],
      },
      {
        id: ESectionIds.Section_TableNew,
        parentId: ETopicIds.Topic_Tables,
        label: 'Nouvelle table',
        subLabel: "",
        subLabelClass: "",
        route: "room/table/edit/0?m=1",
        rolesAllowed: [
          ERoleCodes.ADMIN,
          ERoleCodes.SUPER,
        ],
      },
      {
        id: ESectionIds.Section_SeatsAvailable,
        parentId: ETopicIds.Topic_Seats,
        label: 'Sièges disponibles',
        subLabel: "",
        subLabelClass: "",
        route: "room/seat/list",
        rolesAllowed: [
          ERoleCodes.ADMIN,
          ERoleCodes.SUPER,
          ERoleCodes.BUYER,
          ERoleCodes.GEST,
        ],
      },
      {
        id: ESectionIds.Section_Activation,
        parentId: ETopicIds.Topic_Gestion,
        label: 'Acheteurs',
        subLabel: "",
        subLabelClass: "", //{ marginTop: '15px', marginRight: '100px' }
        route: "user/buyer/activation",
        rolesAllowed: [
          ERoleCodes.ADMIN,
          ERoleCodes.SUPER,
          ERoleCodes.GEST,
        ],
      },
      {
        id: ESectionIds.Section_Buyers,
        parentId: ETopicIds.Topic_Admin,
        label: 'Utilisateurs',
        subLabel: "",
        subLabelClass: "", //{ marginTop: '15px', marginRight: '100px' }
        route: "user/list",
        rolesAllowed: [
          ERoleCodes.ADMIN,
          ERoleCodes.SUPER,
          ERoleCodes.GEST,
        ],
      },
      {
        id: ESectionIds.Section_CreateUser,
        parentId: ETopicIds.Topic_Admin,
        label: 'Nouveau',
        subLabel: "utilisateur",
        subLabelClass: "", //{ marginTop: '15px', marginRight: '100px' }
        route: "user/create",
        rolesAllowed: [
          ERoleCodes.ADMIN,
          ERoleCodes.SUPER,
        ],
      },
      {
        id: ESectionIds.Section_Accounts,
        parentId: ETopicIds.Topic_Gestion,
        label: 'Comptes',
        subLabel: "",
        subLabelClass: "", //{ marginTop: '15px', marginRight: '100px' }
        route: "user/buyer/account",
        rolesAllowed: [
          ERoleCodes.ADMIN,
          ERoleCodes.SUPER,
          ERoleCodes.GEST,
        ],
      },
    ];

    constructor (
      private store: Store<{ nav: INavState }>,
      private connectionService: ConnectionService,
    ) {}

    ngOnInit(): void {
      this.selectedTopicId$ = this.store.select(selectTopicId);
  
      this.filteredSections$ = combineLatest([this.roles$, of(this.sections), this.selectedTopicId$]).pipe(
        map(([roles, sections, selectedTopicId]) =>
          sections.filter(section =>
            section.parentId === selectedTopicId &&
            (section.rolesAllowed ? section.rolesAllowed.some(r => roles.includes(r)) : true) &&
            (section.rolesDenied ? !section.rolesDenied.some(r => roles.includes(r)) : true)
          )
        )
      );
    }

    onClicked(item: INavItem): void {
      this.selectedSectionId = item.id;
      this.store.dispatch(new SelectSection({
        newSection: item.id,
        route: item.route
      }))

    }
}
