<div class="table-edit-container">
  <!-- Afficher le plugin edit ou view en fonction de editMode -->
  <ng-container *ngIf="editMode; else viewMode">
    <plugin-table-edit 
      [options]="tableOptions" 
      (submitted)="updateSubmitted($event)" 
      (canceled)="cancel()">
    </plugin-table-edit>
  </ng-container>
  <ng-template #viewMode>
    <plugin-table-view 
      [options]="tableOptions">
    </plugin-table-view>
  </ng-template>

  <!-- Bouton pour entrer en mode édition si on n'y est pas déjà -->
  <button *ngIf="isAdminOrSuper && !editMode" (click)="enterEditMode()">Modifier</button>
</div>

<div class="plugin-container">
  <!-- Seat Picker Plugin -->
  <plugin-seat-picker 
    [options]="seatPickerOptions">
  </plugin-seat-picker>

  <!-- Seat Edit Plugin -->
  <plugin-seat-edit 
    *ngIf="isAdminOrSuper"
    [options]="addSeatOptions" 
    (submitted)="submitNewSeat($event)">
  </plugin-seat-edit>
</div>
