import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SeatingPlanRoute } from './plan/route/seatingplan.route';
import { ReactiveFormsModule } from '@angular/forms';
import { RoomRoutingModule } from './room-routing.module'

@NgModule({
  declarations: [
    SeatingPlanRoute,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    RoomRoutingModule,
  ],
  exports: [
    SeatingPlanRoute,
  ]
})
export class RoomModule { }
