<div class="seat-list-manager">
    <table>
      <thead>
        <tr>
          <th>Label</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let seat of list">
          <td>{{ seat.label }}</td>
          <td>
            <plugin-seat-inline-manager [seatDto]="seat.getDto()" (refresh)="refresh()"></plugin-seat-inline-manager>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  