import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISeatDto } from '../../seat.dto';
import { Seat } from '../../seat.class';
import { FormsModule } from '@angular/forms';
import { Buyer } from 'src/app/user/buyer/buyer.class';
import { User } from 'src/app/user/user/user.class';

@Component({
  selector: 'plugin-seat-inline-booking',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './seat-inline-booking.plugin.html',
  styleUrls: ['./seat-inline-booking.plugin.scss']
})
export class SeatInlineBookingPlugin {
  private _dto: ISeatDto = {};
  @Input()
  set seatDto(value: ISeatDto) {
    this._dto = value;
    this.load();
  };
  get seatDto() {
    return this._dto;
  }

  @Output() bookSeat: EventEmitter<ISeatDto> = new EventEmitter();
  @Output() cancelBooking: EventEmitter<void> = new EventEmitter();

  constructor() {}

  seat: Seat = new Seat();
  reservationName: string = '';

  load() {
    this.seat = new Seat(this.seatDto);
    console.log(this.seat);
  }

  onBookSeat() {
    // on considère l'achat du siège par le user courant
    this.seat.bookingName = this.reservationName;
    this.bookSeat.emit(this.seat.getDto());
  };

  onCancelBooking() {
    this.cancelBooking.emit();
  };
}
