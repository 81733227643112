<div class="user-role-mapper-container">
    <div class="role-picker-container">
        <div class="role-picker">
            <h4>Rôles disponibles</h4>
            <plugin-user-role-picker 
                [options]="availableUserRolesOptions" 
                (itemSelected)="selectAvailableItem($event)">
            </plugin-user-role-picker>
        </div>
      
        <div class="role-mapping-controls">
            <button 
                (click)="assignRole()" 
                class="assign-button" 
                [disabled]="!availableRoleSelected">→</button>
            <button 
                (click)="removeRole()" 
                class="remove-button" 
                [disabled]="!assignedRoleSelected">←</button>
        </div>
      
        <div class="role-picker">
            <h4>Rôles affectés</h4>
            <plugin-user-role-picker 
                [options]="assignedRUserolesOptions" 
                (itemSelected)="selectAssignedItem($event)">
            </plugin-user-role-picker>
        </div>
    </div>

    <div class="footer">
        <button (click)="load()" class="refresh-button">Rafraîchir</button>
    </div>
</div>
