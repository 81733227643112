import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalRef, BsModalService }     from 'ngx-bootstrap/modal';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserLoginModal } from '../../user/connect/modal/login.modal';
import { ConnectionService } from 'src/app/user/connect/connection.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss']
})
export class BurgerMenuComponent implements OnInit {
  modalRef?: BsModalRef;
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;

  isLoggedIn$: Observable<boolean>;

  constructor(
    private modalService: BsModalService,
    private connectionService: ConnectionService,
    private router: Router, 
  ) {
    this.isLoggedIn$ = this.connectionService.isLoggedIn$;
  }

  ngOnInit() {}

  Connecter(event: Event) {
    event.stopPropagation();

    this.modalRef = this.modalService.show(UserLoginModal, {});
    this.trigger.closeMenu();
  }

  Deconnecter() {
    this.connectionService.logoutUser();
    this.trigger.closeMenu();
    this.router.navigate(['/']);
  }

  VoirProfil(event: Event) {
    event.stopPropagation();
    this.trigger.closeMenu();
    this.router.navigate(['user/profile'])
  }
}
