import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from './user/connect/connection.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {
  constructor(
    private router: Router,
    private connectionService: ConnectionService,
  ) {}

  handleAuthError(): void {
    this.connectionService.logoutUser();
    this.router.navigate(['/login']); // Rediriger vers la page de connexion
  }
}
