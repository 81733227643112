import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard, AuthGuard } from '../auth.guard';
import { TableListRoute } from './table/route/list/table-list.route';
import { TableEditRoute } from './table/route/edit/table-edit.route';
import { TableListManagerRoute } from './table/route/list-manager/table-list-manager.route';

const routes: Routes = [
  { 
    path: 'table/list', 
    component: TableListRoute,
    canActivate: [AuthGuard]
  },  
  { 
    path: 'table/edit/0?m=1', 
    component: TableEditRoute,
    canActivate: [AuthGuard]
  },
  { 
    path: 'table/edit/:idTable', 
    component: TableEditRoute,
    canActivate: [AuthGuard]
  },  
  { 
    path: 'seat/list', 
    component: TableListManagerRoute,
    canActivate: [AuthGuard]
  },  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoomRoutingModule {}