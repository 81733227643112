import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITableDto, ITableRequestOptions } from '../../table.dto';
import { Router } from '@angular/router';
import { Table } from '../../table.class';
import { TablePickerPlugin } from '../../plugin/picker/table-picker.plugin';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TablePickerPlugin,
  ],
  templateUrl: './table-list.route.html',
  styleUrls: ['./table-list.route.scss']
})
export class TableListRoute {
  options: ITableRequestOptions = {
    loadSeats: true,
  };

  constructor(private router: Router) {} 

  viewItem(dto: ITableDto) {
    const table: Table = new Table(dto);
    this.router.navigate(['/table/edit', table.id], { queryParams: { m: 0 } });
  }
}
