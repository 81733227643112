import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SeatingPlanRoute } from './room/plan/route/seatingplan.route';
import { AdminGuard, AuthGuard } from './auth.guard';
import { WelcomeRoute } from './annoucement/welcome/welcome.route';
import { NotAuthorizedRoute } from './user/user/route/not-authorized/not-authorized.route';
 
const routes: Routes = [
  { 
    path: '', 
    component: WelcomeRoute,
    canActivate: []
  },  
  { 
    path: 'not-authorized', 
    component: NotAuthorizedRoute,
    canActivate: []
  },
  { 
    path: 'plan', 
    component: SeatingPlanRoute,
    canActivate: []
  },
  { 
    path: 'tables', component: SeatingPlanRoute 
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule) // Utilisation du chargement différé
  },
  {
    path: 'room',
    loadChildren: () => import('./room/room.module').then(m => m.RoomModule) // Utilisation du chargement différé
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
