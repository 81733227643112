<div *ngIf="item && item.idTable !== 0; else noItem">
  <form [formGroup]="itemForm">
    <div>
      <label for="label">Nom du siège :</label>
      <input id="label" formControlName="label" required />
    </div>
    <div>
      <button type="button" (click)="save()">Sauvegarder</button>
      <button type="button" (click)="cancel()">Annuler</button>
    </div>
  </form>
</div>
<ng-template #noItem>
  <p>No item selected.</p>
</ng-template>
