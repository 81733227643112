import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITableDto, ITableRequestOptions } from '../../table.dto';
import { TableService } from '../../table.service';
import { Table } from '../../table.class';

@Component({
  selector: 'plugin-table-picker',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './table-picker.plugin.html',
  styleUrls: ['./table-picker.plugin.scss']
})
export class TablePickerPlugin {
  private _options: ITableRequestOptions = {};
  @Input()
  set options(value: ITableRequestOptions) {
    this._options = value;
    this.loadList();
  }
  get options(): ITableRequestOptions {
    return this._options;
  }

  // output
  @Output() selected: EventEmitter<ITableDto> = new EventEmitter();    // simple clic
  @Output() activated: EventEmitter<ITableDto> = new EventEmitter();   // double-click
  @Output() viewSeats: EventEmitter<ITableDto> = new EventEmitter();   
  @Output() tableUpdate: EventEmitter<Partial<ITableDto>> = new EventEmitter();   

  list: Table[] = [];

  _selectedItem: Table = new Table();
  set selectedItem(value: Table) {
    this._selectedItem = value;
  }
  get selectedItem(): Table {
    return this._selectedItem;
  }
  
  constructor(
    private tableService: TableService,
  ) {}

  loadList() {
    this.tableService.list(this.options).subscribe(data => {
      if (data) {
        this.list = data;
      }
    })
  }

  selectItem(item: Table) {
    this.selectedItem = item;
    this.selected.emit(this.selectedItem.getDto());
  }

  activateItem(item: Table) {
    this.selectedItem = item;
    this.selected.emit(this.selectedItem.getDto());
  }

  viewItem(event: Event, item: Table) {
    event.preventDefault();
    this.selectedItem = item; //new Table(dto);
    this.activated.emit(this.selectedItem.getDto());
  }
}
