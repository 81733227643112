import { Seat } from "../seat/seat.class";
import { ITableDto } from "./table.dto";

export class Table {
    id: number = 0;
    name: string = '';
    seats?: Seat[];

    constructor(
        dto?: ITableDto,
    ) {
        if (dto) {
            this.loadDto(dto);
        }
    }

    loadDto(dto: ITableDto) {
        console.log(dto);
        this.id = dto.idTable ? dto.idTable : 0;
        this.name = dto.name ? dto.name : '';
    }

    getDto(): ITableDto {
        const dto: ITableDto = {
            idTable: this.id !== 0 ? this.id : undefined,
            name: this.name,
            seats: this.seats ? this.seats.map(s => s.getDto()) : undefined,
        }
        return dto;
    }
}
