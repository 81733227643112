<div class="new-password-container">
    <h2>Définir un nouveau mot de passe pour {{ username }}</h2>
  
    <div class="form-group">
      <label for="password">Nouveau mot de passe</label>
      <input type="password" id="password" [(ngModel)]="password" (input)="validatePasswords()" />
    </div>
  
    <div class="form-group">
      <label for="confirmPassword">Confirmer le mot de passe</label>
      <input type="password" id="confirmPassword" [(ngModel)]="confirmPassword" (input)="validatePasswords()" />
      <div *ngIf="passwordMismatch" class="error-message">Les mots de passe ne correspondent pas.</div>
    </div>
  
    <div class="form-actions">
      <button (click)="submitPassword()" [disabled]="passwordMismatch || !password || !confirmPassword">Valider</button>
      <button (click)="cancel()">Annuler</button>
    </div>
  </div>
  