import { Injectable } from '@angular/core';
import { ISeatDto } from './seat.dto';
import { catchError, map, Observable } from 'rxjs';
import { Seat } from './seat.class';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ISeatRequestOptions } from './seat.request';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class SeatService {

  constructor(
    private apiService: ApiService,
  ) { }

  release(seat: Seat): Observable<Seat | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.seatRelease}`;
    console.log('Request URL:', url);
  
    return this.apiService.post<ISeatDto>(url, seat.getDto()).pipe(
      map(dto => {
        console.log('API response DTO:', dto);
        return new Seat(dto);
      }),
      catchError(this.apiService.handleError.bind(this))
    );
  }
  
  book(seat: Seat): Observable<Seat | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.seatBook}`;
    console.log('Request URL:', url);
  
    return this.apiService.post<ISeatDto>(url, seat.getDto()).pipe(
      map(dto => {
        console.log('API response DTO:', dto);
        return new Seat(dto);
      }),
      catchError(this.apiService.handleError.bind(this))
    );
  }
  
  get(id: number, options: ISeatRequestOptions = {}): Observable<Seat | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.seatGet}/${id}`;
    console.log('Request URL:', url);
  
    let params = new HttpParams()
      .set('tbl', options.loadTable ? '1' : '0')
      .set('buy', options.loadBuyer ? '1' : '0');
  
    return this.apiService.get<ISeatDto>(url, { params }).pipe(
      map(dto => new Seat(dto)),
      catchError(this.apiService.handleError.bind(this))
    );
  }  

  save(seat: Seat): Observable<Seat | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.seatSave}`;
    console.log('Request URL:', url);
  
    return this.apiService.post<ISeatDto>(url, seat.getDto()).pipe(
      map(dto => new Seat(dto)),
      catchError(this.apiService.handleError.bind(this))
    );
  }  

  list(options: ISeatRequestOptions): Observable<Seat[] | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.seatList}`;
  
    let params = new HttpParams()
      .set('tbl', options.loadTable ? '1' : '0')
      .set('buy', options.loadBuyer ? '1' : '0');
  
    if (options.ids && options.ids.length > 0) {
      const idSequence: string = options.ids.join(',');
      params = params.set('ids', idSequence);
    }
  
    if (options.tableIds && options.tableIds.length > 0) {
      const tableIdsSeq: string = options.tableIds.join(',');
      params = params.set('tis', tableIdsSeq);
    }
  
    return this.apiService.get<ISeatDto[]>(url, { params }).pipe(
      map(dtos => dtos.map(dto => new Seat(dto))),
      catchError(this.apiService.handleError.bind(this))
    );
  }
  
}
