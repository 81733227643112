import { Component, EventEmitter, Input, Output  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Seat } from '../../seat.class';
import { ISeatDto } from '../../seat.dto';
import { SeatInlineStatePlugin } from '../inline-state/seat-inline-state.plugin';
import { SeatInlineBookingPlugin } from '../inline-booking/seat-inline-booking.plugin';
import { SeatService } from '../../seat.service';
import { ConnectionService } from '../../../../user/connect/connection.service';

@Component({
  selector: 'plugin-seat-inline-manager',
  standalone: true,
  imports: [
    CommonModule,
    SeatInlineBookingPlugin,
    SeatInlineStatePlugin,
  ],
  templateUrl: './seat-inline-manager.plugin.html',
  styleUrls: ['./seat-inline-manager.plugin.scss']
})
export class SeatInlineManagerPlugin {
  private _dto: ISeatDto = {};
  @Input()
  set seatDto(value: ISeatDto) {
    this._dto = value;
    this.load();
  };
  get seatDto() {
    return this._dto;
  }

  @Output() refresh: EventEmitter<void> = new EventEmitter();

  username$ = this.connectionService.username$;

  constructor(
    private seatService: SeatService,
    private connectionService: ConnectionService,
  ) {}

  seat: Seat = new Seat();
  mode: number = 0;

  load() {
    this.seat = new Seat(this.seatDto);
    console.log(this.seat);
  }

  bookSeat() {
    this.mode = 1;
  }

  releaseSeat(dto: ISeatDto) {
    this.seat = new Seat(dto);    // bookingName est déjà inclus
    // buyer par défaut (session)
    this.seatService.release(this.seat).subscribe(s => {
      if (!s) {
        console.error('ça va pas.');
      } else {
        this.seatDto = s.getDto();
        this.refreshContent();
      }
    });
  }

  bookingConfirmed(dto: ISeatDto) {
    this.seat = new Seat(dto);    // bookingName est déjà inclus
    // buyer par défaut (session)
    this.seatService.book(this.seat).subscribe(s => {
      if (!s) {
        console.error('ça va pas.');
      } else {
        this.seatDto = s.getDto();
        this.refreshContent();
      }
    });
  }

  bookingCanceled() {
    this.mode = 0;
    this.refreshContent();
  }

  refreshContent() {
    this.refresh.emit();
  }

}
