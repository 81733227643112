<div *ngIf="user; else noUser">
    <h2>User Details</h2>
    <table class="user-details-table">
      <tr>
        <th>Username:</th>
        <td>{{ user.username }}</td>
      </tr>
      <tr>
        <th>Email:</th>
        <td>{{ user.email }}</td>
      </tr>
      <tr>
        <th>Active:</th>
        <td>{{ user.isActive ? 'Yes' : 'No' }}</td>
      </tr>
      <tr>
        <th>Start Date:</th>
        <td>{{ user.dtStart | date: 'yyyy-MM-dd' }}</td>
      </tr>
      <tr>
        <th>End Date:</th>
        <td>{{ user.dtEnd | date: 'yyyy-MM-dd' }}</td>
      </tr>
    </table>
  </div>
  <ng-template #noUser>
    <p>No user selected.</p>
  </ng-template>
  