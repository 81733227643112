import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Buyer } from '../../buyer.class';
import { FormsModule, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IBuyerDto } from '../../buyer.dto';

@Component({
  selector: 'plugin-buyer-inline-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './buyer-inline-edit.plugin.html',
  styleUrls: ['./buyer-inline-edit.plugin.scss']
})
export class BuyerInlineEditPlugin implements OnChanges {
  @Input()
  set buyerDto(value: IBuyerDto) {
    this.load(value);
  }
  get buyerDto(): IBuyerDto {
    return this.buyer.getDto();
  }

  @Output() newValue: EventEmitter<IBuyerDto> = new EventEmitter();

  buyer: Buyer = new Buyer();
  buyerForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.buyerForm = this.fb.group({
      seatCount: [0]
    });

    // Ecouter les changements de valeur dans le formulaire
    this.buyerForm.get('seatCount')!.valueChanges.subscribe(value => {
      this.onSeatCountChange(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['buyerDto'] && changes['buyerDto'].currentValue) {
      this.load(changes['buyerDto'].currentValue);
    }
  }

  load(dto: IBuyerDto) {
    if (!dto) {
      console.log('load: No DTO provided, disabling');
    } else {
      this.buyer = new Buyer(dto);
      this.buyerForm.patchValue({ seatCount: this.buyer.seatCount });
    }
  }

  incrementSeatCount() {
    const seatCount = this.buyerForm.get('seatCount')!.value;
    this.buyerForm.patchValue({ seatCount: seatCount + 1 });
  }

  decrementSeatCount() {
    const seatCount = this.buyerForm.get('seatCount')!.value;
    if (seatCount > 0) {
      this.buyerForm.patchValue({ seatCount: seatCount - 1 });
    }
  }

  onSeatCountChange(value: number) {
    if (this.buyer.seatCount !== value) {
      this.buyer.seatCount = value;
      this.newValue.emit(this.buyer.getDto());
    }
  }
}
