import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRolePickerPlugin } from '../../../role/plugin/picker/user-role-picker.plugin';
import { IUserRoleRequestOptions } from 'src/app/user/role/userrole.request';
import { ConnectionService } from 'src/app/user/connect/connection.service';
import { of } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    UserRolePickerPlugin,
  ],
  templateUrl: './profile.route.html',
  styleUrls: ['./profile.route.scss']
})
export class ProfileRoute implements OnInit {
  username$ = this.connectionService.username$;
  urOptions: IUserRoleRequestOptions = {}

  constructor(
    private connectionService: ConnectionService,
  ) {}

  ngOnInit(): void {
    this.username$.subscribe(username => {
      if (username) {
        this.urOptions = {
          username: username,
          assigned: true,
        }
      }
    });
  }
}
