<div class="buyer-account-list">
    <table>
      <thead>
        <tr>
          <th>Username</th>
          <th>Quota</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let buyer of list">
          <td>{{ buyer.user?.username }}</td>
          <td>
            <plugin-buyer-inline-edit [buyerDto]="buyer.getDto()" (newValue)="handleNewValue($event)"></plugin-buyer-inline-edit>
          </td>
        </tr>
      </tbody>
    </table>
</div>
  