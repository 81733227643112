import { createFeatureSelector, createSelector } from "@ngrx/store";
import { INavState } from "./nav.state";
import { NAV_STORE_KEY } from "./nav.share";

// Sélectionne le feature state complet
export const selectNavFeatureState = createFeatureSelector<INavState>(NAV_STORE_KEY);

export const topicSelector = createSelector(
  selectNavFeatureState,
  (state: INavState) => state.idTopic
);

export const sectionSelector = createSelector(
    selectNavFeatureState,
    (state: INavState) => state.idSection
  );

export const selectNavState = createFeatureSelector<INavState>(NAV_STORE_KEY);

export const selectTopicId = createSelector(
  selectNavState,
  (state: INavState) => state.idTopic
);