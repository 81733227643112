import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, concatMap, tap } from 'rxjs/operators';
import { NavActionsTypes, SelectSection } from './nav.actions';

@Injectable()
export class NavEffects {

  navigateToSection$ = createEffect(() => this.actions$.pipe(
    ofType(NavActionsTypes.SELECT_SECTION),
    concatMap((action: SelectSection) =>
      of(action).pipe(
        tap((action) => {
          const route = action.payload.route;
          // Si 'route' est défini dans le payload, naviguez vers cette route
          if (route) {
            this.router.navigateByUrl(route);
          }
        }),
        catchError((error) => {
          // Gérer l'erreur si nécessaire
          console.error('Navigation Error:', error);
          return of();
        })
      )
    )
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router
  ) {}
}
