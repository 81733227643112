import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Input } from '@angular/core';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  @Input() type: 'short' | 'long' = 'long';
  version: string = "non disponible";
  label: string = "non disponible";

  constructor () {}

  ngOnInit(): void {
      this.label = environment.label;
      this.version = environment.version;
      console.log(VersionComponent.name, this.label, this.version);
  }
}
