import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IBuyerRequestOptions } from '../../buyer.request';
import { BuyerActivationListPlugin } from '../../plugin/activation-list/buyer-activation-list.plugin';
import { IUserDto } from 'src/app/user/user/user.dto';
import { User } from 'src/app/user/user/user.class';
import { Buyer } from '../../buyer.class';
import { BuyerService } from '../../buyer.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    BuyerActivationListPlugin,
  ],
  templateUrl: './buyer-activation-list.route.html',
  styleUrls: ['./buyer-activation-list.route.scss']
})
export class BuyerActivationListRoute implements OnInit {
  options: IBuyerRequestOptions = {};

  constructor(
    private buyerService: BuyerService,
  ) {}

  ngOnInit(): void {
      this.loadActivationPlugin();
  }

  loadActivationPlugin() {
    this.options = {};
  }

  removeBuyer(dto: IUserDto) {
    console.log('dto', dto);
    console.log('buyer', dto.buyer);
    const idBuyer: number = dto.buyer?.idBuyer || 0;
    if (idBuyer !== 0) {
      this.buyerService.delete(idBuyer).subscribe(v => {
        this.loadActivationPlugin();
      });
    } else {
      console.error('tu fais quoi ?');
    }
  }

  addBuyer(dto: IUserDto) {
    const user: User = new User(dto);
    const buyer: Buyer = new Buyer();
    buyer.username = user.username;
    buyer.seatCount = 0;
    this.buyerService.save(buyer).subscribe(b => {
      if (!b) {
        console.error('sauvegarde ko');
      } else {
        this.loadActivationPlugin();
      }
    })
  }
}
