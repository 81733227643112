import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-test-modale',
  templateUrl: './test-modale.component.html',
  styleUrls: ['./test-modale.component.scss']
})
export class TestModaleComponent {
  profileForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
  });

  constructor(
    public modalRef: BsModalRef
  ) {}
}
