/*
  _____                   _                   ____                                                              _   
 |_   _|   ___    _ __   (_)   ___   ___     / ___|   ___    _ __ ___    _ __     ___    _ __     ___   _ __   | |_ 
   | |    / _ \  | '_ \  | |  / __| / __|   | |      / _ \  | '_ ` _ \  | '_ \   / _ \  | '_ \   / _ \ | '_ \  | __|
   | |   | (_) | | |_) | | | | (__  \__ \   | |___  | (_) | | | | | | | | |_) | | (_) | | | | | |  __/ | | | | | |_ 
   |_|    \___/  | .__/  |_|  \___| |___/    \____|  \___/  |_| |_| |_| | .__/   \___/  |_| |_|  \___| |_| |_|  \__|
                 |_|                                                    |_|                                         
*/
import { Component, OnInit }              from '@angular/core';
import { Store }                          from '@ngrx/store';
import { map }                            from 'rxjs/operators';
import { Observable, combineLatest, of }  from 'rxjs';
import { INavItem, ETopicIds }            from '../nav.share';
import { INavState }                      from '../nav.state';
import { SelectTopic }                    from '../nav.actions';
import { ERoleCodes }                     from '../../user/role/role.enum';
import { ConnectionService }              from '../../user/connect/connection.service';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})

export class TopicsComponent implements OnInit {
  roles$ = this.connectionService.roles$;
  filteredTopics$!: Observable<INavItem[]>;
  selectedTopicId?: string;

  // ------------------------------------------ définition des topics
  topics: INavItem[] = [
    {
      id: ETopicIds.Topic_Tables,
      label: 'Tables',
      labelStyles: {},    //{ borderBottom: '2px solid #fff' }
    },
    {
      id: ETopicIds.Topic_Seats,
      label: 'Sièges',
      labelStyles: {},
      rolesAllowed: [
        ERoleCodes.ADMIN,
        ERoleCodes.SUPER,
        ERoleCodes.GEST,
        ERoleCodes.BUYER,
      ],
    },
    {
      id: ETopicIds.Topic_Gestion,
      label: 'Gestion',
      labelStyles: {},
      rolesAllowed: [
        ERoleCodes.ADMIN,
        ERoleCodes.SUPER,
        ERoleCodes.GEST,
      ],
    },
    {
      id: ETopicIds.Topic_Admin,
      label: 'Administration',
      labelStyles: {},
      rolesAllowed: [
        ERoleCodes.ADMIN,
        ERoleCodes.SUPER,
      ],
    }
  ];
  
  constructor (
    private store: Store<{ nav: INavState }>,
    private connectionService: ConnectionService,
  ) {};

  ngOnInit(): void {
    this.filteredTopics$ = combineLatest([this.roles$, of(this.topics)]).pipe(
      map(([roles, topics]) =>
        topics.filter(topic =>
          (topic.rolesAllowed ? topic.rolesAllowed.some(r => roles.includes(r)) : true) &&
          (topic.rolesDenied ? !topic.rolesDenied.some(r => roles.includes(r)) : true)
        )
      )
    );
  }

  onClicked(item: INavItem): void {
    this.selectedTopicId = item.id;
    this.store.dispatch(new SelectTopic({
      newTopic: item.id
    }))
  }
  
}
