<div>
  <h1>Edit User: {{ username }}</h1>
  
  <!-- Afficher le plugin edit ou view en fonction de editMode -->
  <ng-container *ngIf="editMode; else viewMode">
    <plugin-user-edit [options]="options" (submitted)="updateSubmitted($event)" (canceled)="cancel()"></plugin-user-edit>
  </ng-container>
  <ng-template #viewMode>
    <plugin-user-view [options]="options"></plugin-user-view>
  </ng-template>
  
  <!-- Bouton pour entrer en mode édition si on n'y est pas déjà -->
  <button *ngIf="!editMode" (click)="enterEditMode()">Modifier</button>
</div>
<div>
  <plugin-user-role-mapper [username]="username"></plugin-user-role-mapper>
</div>