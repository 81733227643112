<table class="picker-item">
  <thead>
    <tr>
      <th>Table</th>
      <th>Etiquette</th>
      <th>Réservation</th>
      <th>Acheteur</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of list" 
        [class.selected]="item.id === selectedItem.id"
        (click)="selectItem(item)" 
        (dblclick)="activateItem(item)">
      <td>{{ item.table?.name }}</td>
      <td>{{ item.label }}</td>
      <td>{{ item.bookingName }}</td>
      <td>{{ item.buyerUsername }}</td>
      <td class="actions">
        <a [style.visibility]="options.showViewButton ? 'visible' : 'hidden'" href="#" (click)="viewItem($event, item)">Voir</a>
      </td>
    </tr>
  </tbody>
</table>
