import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISeatDto } from '../../seat.dto';
import { Seat } from '../../seat.class';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SeatService } from '../../seat.service';
import { ISeatRequestOptions } from '../../seat.request';

@Component({
  selector: 'plugin-seat-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './seat-edit.plugin.html',
  styleUrls: ['./seat-edit.plugin.scss']
})
export class SeatEditPlugin implements OnInit {
  private _options: ISeatRequestOptions = {};
  @Input()
  set options(value: ISeatRequestOptions) {
    this._options = value;
    this.load();
  }
  get options(): ISeatRequestOptions {
    return this._options;
  };
  
  @Output() submitted: EventEmitter<ISeatDto> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  item: Seat | null = null;
  itemForm: FormGroup;

  constructor(
    readonly seatService: SeatService,
    private fb: FormBuilder,
  ) {
    this.itemForm = this.fb.group({
      label: [''],
    });
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    console.log('load', this.options);
    if (this.options.id) {
      this.seatService.get(this.options.id, this.options).subscribe(seat => {
        console.log('seat get result', seat)
        this.item = seat;
        if (this.item) {
          this.itemForm.patchValue({
            label: this.item.label,
          });
        } else {
          this.item = new Seat();
          this.item.idTable = this.options.idTable || 0;
          console.log('nouveau');
        }
      });
    } else {
      this.item = new Seat();
      this.item.idTable = this.options.idTable || 0;
    }
  }
  
  save(): void {
    if (this.item) {
      this.item.label = this.itemForm.get('label')?.value;
      this.submitted.emit(this.item.getDto());
    }
  }

  cancel(): void {
    this.load(); // Recharger les données de l'utilisateur pour annuler les modifications
    this.canceled.emit();
  }
}
