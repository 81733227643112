import { Buyer } from "../buyer/buyer.class";
import { IUserDto } from "./user.dto";

export class User {
    // #region Attributes
    username: string = '';
    email: string = '';
    password_deprecated: string = '';              // deprecated
    clrPassword: string = '';           // new
    isActive: boolean = true;
    dtStart?: Date | null = null;
    dtEnd?: Date | null = null;

    buyer?: Buyer;

    activeRoleCodes?: string[];
    // #endregion

    // #region Properties
    // get isNew(): boolean { return this.id === 0; }

    // #endregion

    constructor(dto?: IUserDto) {
        if (dto) {
            this.loadDto(dto);
        }
    }

    loadDto(dto: IUserDto) {
        this.username = dto.username ?? '';
        this.email = dto.emailAddress ?? '';
        this.password_deprecated = dto.userPassword_deprecated ?? '';
        this.clrPassword = dto.password ?? '';      // new
        this.dtStart = dto.dtStart ?? null;
        this.dtEnd = dto.dtEnd ?? null;
        this.isActive = dto.isActive ?? true;
        this.buyer = dto.buyer ? new Buyer(dto.buyer) : undefined;
        this.activeRoleCodes = dto.activeRoleCodes ? dto.activeRoleCodes : undefined;
    }

    getDto(): IUserDto {
        const dto: IUserDto = {
            username: this.username,
            emailAddress: this.email,
            userPassword_deprecated: this.password_deprecated,
            password: this.clrPassword,
            dtStart: this.dtStart,
            dtEnd: this.dtEnd,
            isActive: this.isActive,
            // roles: this.userRoles ? this.userRoles.map(r => r.getDto()) : undefined,
            buyer: this.buyer ? this.buyer.getDto() : undefined,
        };
        return dto;
    }

}
