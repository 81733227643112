import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ILoginRequestDto, ILoginResponseDto } from './userLoginDto';
import { IUserDto } from './user.dto';
import { User } from './user.class';
import { IUserRequestOptions } from './user.request';
import { ApiService } from '../../api.service';
import { ConnectionService } from '../connect/connection.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private connectionService: ConnectionService,
  ) {}

  partialUpdate(
    username: string,
    partialDto: Partial<IUserDto>,
  ): Observable<User | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.userUpdate}/${username}`;
    console.log(url);
  
    return this.apiService.patch<IUserDto>(url, partialDto).pipe(
      map(dto => new User(dto)),
      catchError(this.apiService.handleError.bind(this)) 
    );
  }  

  /**
   * mise à jour partielle
   * le dto en entrée doit contenir le username du user à modifier
   */
  newPassword(
    username: string,
    password: string,
    clearPassword: string,
  ): Observable<User | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.userPassword}/${username}/pwd`;
    const dto: Partial<IUserDto> = {
      userPassword_deprecated: password,
      password: clearPassword,
    };
    console.log(url);
  
    return this.apiService.patch<IUserDto>(url, dto).pipe(
      map(dto => new User(dto)),
      catchError(this.apiService.handleError.bind(this)) 
    );
  }
  
  create(
    user: User,
  ): Observable<User | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.userCreate}`;
    console.log(url);
    console.log("data", user);
    console.log('dto', user.getDto());
  
    return this.apiService.post<IUserDto>(url, user.getDto()).pipe(
      map(dto => new User(dto)),
      catchError(this.apiService.handleError.bind(this)) 
    );
  }  

  get(
    username: string,
    options: IUserRequestOptions = {},
  ): Observable<User | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.userGet}/${username}`;
    console.log(url);
  
    // Construire les paramètres de requête en fonction des options
    let params = new HttpParams();
    if (options.loadBuyer) {
      params = params.set('buy', '1');
    }
  
    return this.apiService.get<IUserDto>(url, {params}).pipe(
      map(dto => new User(dto)),
      catchError(this.apiService.handleError.bind(this)) 
    );
  }

  list(options: IUserRequestOptions): Observable<User[] | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.userList}`;
    let params = new HttpParams();
    if (options.loadBuyer) {
      params = params.set('buy', '1');
    }
  
    return this.apiService.get<IUserDto[]>(url, {params}).pipe(
      map(dtos => {
        console.log('Received dtos:', dtos);
        return dtos.map(dto => new User(dto));
      }),
      catchError(this.apiService.handleError.bind(this)) 
    );
  }

  save(user: User): Observable<User | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.userSave}`;
    console.log(url);

    return this.apiService.post<IUserDto>(url, user.getDto()).pipe(
        map(dto => new User(dto)),
        catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              this.connectionService.logoutUser();
                // this.store.dispatch(logout());
            }
            return throwError(error);
        })
    );
  }

  login(
    dto: ILoginRequestDto,
  ): Observable<ILoginResponseDto> {
    return this.http.post<ILoginResponseDto>(
      environment.api1.connexion.url + environment.api2.routes.authLogin, 
      dto,
    );
  }

}
