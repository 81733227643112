import { Action } from "@ngrx/store";
import { ESectionIds, ETopicIds } from "./nav.share";

export enum NavActionsTypes {
    TOOGLE_AUX_PANEL = "[Nav] Toggle Aux Panel",
    SELECT_TOPIC = "[Nav] Select Topic",
    SELECT_SECTION = "[Nav] Select Section",
    DISPLAY_MESSAGE = "[Nav] Display Message",
    DISPLAY_ERROR = "[Nav] Display Error"
}

export class ToggleAuxPanel implements Action {
    readonly type = NavActionsTypes.TOOGLE_AUX_PANEL;
}

export class SelectTopic implements Action {
    readonly type = NavActionsTypes.SELECT_TOPIC;
    constructor (
        public payload: {
            newTopic: ETopicIds | ESectionIds;
        }
    ) {};
}

export class SelectSection implements Action {
    readonly type = NavActionsTypes.SELECT_SECTION;
    constructor (
        public payload: {
            newSection: ETopicIds | ESectionIds;
            route: string | undefined;
        }
    ) {};
}

export class DisplayMessage implements Action {
    readonly type = NavActionsTypes.DISPLAY_MESSAGE;
    constructor (
        public payload: { infoMessage: string }
    ) {};
}

export class DisplayError implements Action {
    readonly type = NavActionsTypes.DISPLAY_ERROR;
    constructor (
        public payload: { errorMessage: string }
    ) {};
}

export type NavActions = ToggleAuxPanel | SelectTopic | SelectSection | DisplayMessage | DisplayError;
