export const environment = {
    appTitle: "Trouve ta place (int)",
    production: false,
    version: require("../../package.json").version,
    label: "Trouve ta place ! - Intégration",
    api1: {
        connexion: {
            url: "https://galaapi1.int.do-good.fr"
        },
    },
    api2: {
        routes: {
            authLogin: '/auth/login',
            buyerDelete: '/buyer',
            buyerGet: '/buyer',
            buyerList: '/buyer/list',
            buyerUpdate: '/buyer',
            buyerSave: '/buyer/save',
            seatBook: '/seat/book',
            seatGet: '/seat',
            seatList: '/seat/list',
            seatRelease: '/seat/release',
            seatSave: '/seat/save',
            tableGet: '/table',
            tableList: '/table/list',
            tableSave: '/table/save',
            userAssignRole: '/user',
            userCreate: '/user/create',
            userGet: '/user/u',
            userList: '/user/list',
            userPassword: '/user',
            userRemoveRole: '/user',
            userRoleCodes: '/user',
            userSave: '/user/save',
            userUpdate: '/user',
        }
    }
};
