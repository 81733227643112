import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITableDto, ITableRequestOptions } from '../../table.dto';
import { Table } from '../../table.class';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TableService } from '../../table.service';

@Component({
  selector: 'plugin-table-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './table-edit.plugin.html',
  styleUrls: ['./table-edit.plugin.scss']
})
export class TableEditPlugin implements OnInit {
  private _options: ITableRequestOptions = {};
  @Input()
  set options(value: ITableRequestOptions) {
    this._options = value;
    this.load();
  }
  get options(): ITableRequestOptions {
    return this._options;
  }
  
  @Output() submitted: EventEmitter<ITableDto> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  item: Table | null = null;
  itemForm: FormGroup;

  constructor(
    readonly tableService: TableService,
    private fb: FormBuilder,
  ) {
    this.itemForm = this.fb.group({
      name: [''],
    });
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    if (this.options.id && this.options.id !== 0) {
      this.tableService.get(this.options.id, this.options).subscribe(table => {
        this.item = table;
        if (this.item) {
          this.itemForm.patchValue({
            name: this.item.name,
          });
        }
      });
    } else {
      // Cas où l'ID est 0 ou non défini, on initialise une nouvelle table
      this.item = new Table();
      this.itemForm.patchValue({
        name: '', // Valeur par défaut pour une nouvelle table
      });
    }
  }

  save(): void {
    if (this.item) {
      this.item.name = this.itemForm.get('name')?.value || '';
      this.submitted.emit(this.item.getDto());
    }
  }

  cancel(): void {
    this.load(); // Recharger les données de l'utilisateur pour annuler les modifications
    this.canceled.emit();
  }
}
