import { User } from '../user/user.class';
import { Seat } from '../../room/seat/seat.class';
import { IBuyerDto } from './buyer.dto';

export class Buyer {
    id: number = 0;
    seatCount: number = 0;
    username: string = '';

    user?: User;
    seats?: Seat[];

    constructor(dto?: IBuyerDto) {
        if (dto) {
            this.loadDto(dto);
        }
    }

    loadDto(dto: IBuyerDto) {
        this.id = dto.idBuyer !== undefined ? parseInt(dto.idBuyer as any, 10) : 0;
        this.username = dto.username || '';
        this.seatCount = dto.seatCount || 0;
        if (dto.user) {
            this.user = new User(dto.user);
        }
    }

    getDto(): IBuyerDto {
        return {
            idBuyer: this.id,
            username: this.username,
            seatCount: this.seatCount,
            user: this.user ? this.user.getDto() : undefined,
            seats: this.seats ? this.seats.map(seat => seat.getDto()) : undefined,
        };
    }
}
