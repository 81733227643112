// auth.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ERoleCodes } from './user/role/role.enum';
import { ConnectionService } from './user/connect/connection.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private connectionService: ConnectionService,
  ) {}

  canActivate(): Observable<boolean> {
    return of(this.connectionService.hasToken()).pipe(
      map(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/']); // Redirection vers la page de connexion si non authentifié
          return false;
        }
        return true;
      })
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private connectionService: ConnectionService,
  ) {}

  canActivate(): Observable<boolean> {
    return of(this.connectionService.getRoleCodes()).pipe(
      map(roleCodes => {
        const isAdmin = roleCodes.includes(ERoleCodes.ADMIN) || roleCodes.includes(ERoleCodes.SUPER);
        if (!isAdmin) {
          this.router.navigate(['/']); // Redirection vers la page d'accueil si l'utilisateur n'est pas admin
          return false;
        }
        return true;
      })
    );
  }
}

