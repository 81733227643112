<table class="user-picker-table">
  <thead>
    <tr>
      <th>Username</th>
      <th>Email</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of list" 
        [class.inactive]="!user.isActive"
        [class.selected]="user.username === selectedUsername"
        (click)="selectUser(user)" 
        (dblclick)="activateUser(user)">
      <td>{{ user.username }}</td>
      <td>{{ user.email }}</td>
      <td class="actions">
        <a href="#" (click)="toggleActive($event, user)">{{ user.isActive ? 'Désactiver' : 'Activer' }}</a>
        <a href="#" (click)="viewUser($event, user)">Voir</a>
        <a href="#" (click)="resetPassword($event, user)">Pwd</a>
      </td>
    </tr>
  </tbody>
</table>
