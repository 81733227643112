<div class="buyer-account-list">
  <table>
    <thead>
      <tr>
        <th>Username</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of list">
        <td>{{ user.username }}</td>
        <td>
          <ng-container *ngIf="!user.buyer">
            <button (click)="addBuyer(user)">Acheteur</button>
          </ng-container>
          <ng-container *ngIf="user.buyer">
            <button class="remove-buyer" (click)="removeBuyer(user)">Retirer</button>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>
