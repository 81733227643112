import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IUserDto } from '../../user.dto';
import { UserService } from '../../user.service';
import { User } from '../../user.class';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IUserRequestOptions } from '../../user.request';

@Component({
  selector: 'plugin-user-edit',
  standalone: true,
  imports: [
    CommonModule, 
    ReactiveFormsModule
  ],
  templateUrl: './user-edit.plugin.html',
  styleUrls: ['./user-edit.plugin.scss']
})
export class UserEditPlugin implements OnInit {
  private _options: IUserRequestOptions = {};
  @Input()
  set options(value: IUserRequestOptions) {
    this._options = value;
    this.load();
  }
  get options(): IUserRequestOptions {
    return this._options;
  };
  
  @Output() submitted: EventEmitter<IUserDto> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  user: User | null = null;
  userForm: FormGroup;

  constructor(
    readonly userService: UserService,
    private fb: FormBuilder,
  ) {
    this.userForm = this.fb.group({
      username: [''],
      email: [''],
      isActive: [false],
      dtStart: [''],
      dtEnd: ['']
    });
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    if (this.options.username) {
      this.userService.get(this.options.username, this.options).subscribe(user => {
        this.user = user;
        if (this.user) {
          this.userForm.patchValue({
            username: this.user.username,
            email: this.user.email,
            isActive: this.user.isActive,
            dtStart: this.user.dtStart ? this.formatDate(this.user.dtStart) : '',
            dtEnd: this.user.dtEnd ? this.formatDate(this.user.dtEnd) : ''
          });
        }
      });
    }
  }
  
  formatDate(date: Date | string): string {
    const d = new Date(date);
    return isNaN(d.getTime()) ? '' : d.toISOString().substring(0, 10);
  }
  save(): void {
    if (this.user) {
      this.user.username = this.userForm.get('username')?.value;
      this.user.email = this.userForm.get('email')?.value;
      this.user.isActive = this.userForm.get('isActive')?.value;
      this.user.dtStart = this.userForm.get('dtStart')?.value ? new Date(this.userForm.get('dtStart')?.value) : null;
      this.user.dtEnd = this.userForm.get('dtEnd')?.value ? new Date(this.userForm.get('dtEnd')?.value) : null;
      
      console.log('save', this.user);
      this.submitted.emit(this.user.getDto());
    }
  }

  cancel(): void {
    this.load(); // Recharger les données de l'utilisateur pour annuler les modifications
    this.canceled.emit();
  }
}
