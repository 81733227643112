import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionComponent } from './version/version.component';
import { PWAInfoComponent } from './PwaInfo/pwainfo.component';



@NgModule({
  declarations: [
    VersionComponent
  , PWAInfoComponent],
  imports: [
    CommonModule
  ],
  exports: [
    VersionComponent
  , PWAInfoComponent]
})
export class InfoEnvModule { }
