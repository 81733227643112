<div *ngFor="let section of filteredSections$ | async" class="sectionflex"
     [ngClass]="section.labelClass"
     [ngStyle]="section.labelStyles"
     (click)="onClicked(section)"
     [class.active]="selectedSectionId === section.id">
  
  <span [ngClass]="section.labelClass"
        [ngStyle]="section.labelStyles">{{ section.label }}</span>
  
  <ng-container *ngIf="section.subLabel && section.subLabel.trim().length">
    <br>
    <span [ngClass]="section.subLabelClass"
          [ngStyle]="section.subLabelStyles">{{ section.subLabel }}</span>
  </ng-container>
  
</div>
