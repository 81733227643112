/*                               _             _          
 _ __     __ _  __   __    ___  | |_    __ _  | |_    ___ 
| '_ \   / _` | \ \ / /   / __| | __|  / _` | | __|  / _ \
| | | | | (_| |  \ V /    \__ \ | |_  | (_| | | |_  |  __/
|_| |_|  \__,_|   \_/     |___/  \__|  \__,_|  \__|  \___|
                                                          
Description de l'état global du module
*/

import { ESectionIds, ETopicIds }             from "./nav.share"

export interface INavState {
    idTopic: ETopicIds | ESectionIds,
    idSection: ETopicIds | ESectionIds,
    showAuxPanel: boolean,
    infoMessage: string,
    errorMessage: string
}

export const initialNavState: INavState = {
    idTopic: ETopicIds.Topic_Tables,
    idSection: ESectionIds.Section_Tables,
    showAuxPanel: true,
    infoMessage: "",
    errorMessage: ""
}
