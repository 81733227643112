import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IBuyerRequestOptions } from '../../buyer.request';
import { BuyerAccountListPlugin } from '../../plugin/account-list/buyer-account-list.plugin';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    BuyerAccountListPlugin,
  ],
  templateUrl: './buyer-account-list.route.html',
  styleUrls: ['./buyer-account-list.route.scss']
})
export class BuyerAccountListRoute {
  options: IBuyerRequestOptions = {};

}
