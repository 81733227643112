import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NewPasswordPlugin } from '../../plugin/password/new-password.plugin';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NewPasswordPlugin,
  ],
  templateUrl: './password.route.html',
  styleUrls: ['./password.route.scss']
})
export class UserPasswordRoute implements OnInit {
  username: string = '';

  constructor(
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.username = params.get('username') || '';
      console.log('Username from URL:', this.username);
    });
  }
}
