import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-pwainfo',
  templateUrl: './pwainfo.component.html',
  styleUrls: ['./pwainfo.component.scss']
})
export class PWAInfoComponent {
  isSupported: boolean = false;

  constructor(private swUpdate: SwUpdate) {
    this.isSupported = swUpdate.isEnabled;
  }
}
