import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCreatePlugin } from '../../plugin/create/create.plugin';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    UserCreatePlugin,
  ],
  templateUrl: './user-create.route.html',
  styleUrls: ['./user-create.route.scss']
})
export class UserCreateRoute {

}
