import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  templateUrl: './not-authorized.route.html',
  styleUrls: ['./not-authorized.route.scss']
})
export class NotAuthorizedRoute {

}
