import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IBuyerRequestOptions } from '../../buyer.request';
import { UserService } from '../../../user/user.service';
import { User } from '../../../user/user.class';
import { IUserDto } from '../../../user/user.dto';
import { IUserRequestOptions } from 'src/app/user/user/user.request';

/**
 * Affiche la liste des utilisateurs sous forme d'acheteurs existants
 * ou nouveaux
 */
@Component({
  selector: 'plugin-buyer-activation-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './buyer-activation-list.plugin.html',
  styleUrls: ['./buyer-activation-list.plugin.scss']
})
export class BuyerActivationListPlugin {
  private _options: IBuyerRequestOptions = {};
  @Input()
  set options(value: IBuyerRequestOptions) {
    this._options = value;
    this.load();
  }
  get options(): IBuyerRequestOptions {
    return this._options;
  }

  @Output() added: EventEmitter<IUserDto> = new EventEmitter();
  @Output() removed: EventEmitter<IUserDto> = new EventEmitter();
  
  list: User[] = [];

  constructor(
    private userService: UserService,
  ) {}

  load() {
    // les options buyers ne servent passauf à déclencher
    // on charge les user + buyer
    const userOptions: IUserRequestOptions = {
      loadBuyer: true,
    };
    let lu: User[] = [];
    this.userService.list(userOptions).subscribe(l => {
      console.log('liste', l);
      if (l) {
        this.list = l;
        // console.log('list', this.list);
      } else {
        console.error('Error loading users');
      }
    }, error => {
      console.error('Error loading data:', error);
    })
  }

  addBuyer(item: User) {
    // Logique pour ajouter un acheteur
    this.added.emit(item.getDto());
  }

  removeBuyer(item: User) {
    // Logique pour supprimer un acheteur
    console.log('buyer to remove', item);
    console.log('dto te remove', item.getDto());
    this.removed.emit(item.getDto());
  }
}
