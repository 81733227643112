<div *ngIf="item; else noItem">
    <form [formGroup]="itemForm">
      <div>
        <label for="name">Nom de la table:</label>
        <input id="name" formControlName="name" required />
      </div>
      <div>
        <button type="button" (click)="save()">Sauvegarder</button>
        <button type="button" (click)="cancel()">Annuler</button>
      </div>
    </form>
  </div>
  <ng-template #noItem>
    <p class="no-item-message">No item selected.</p>
  </ng-template>
  