import { Injectable } from '@angular/core';
import { IBuyerRequestOptions } from './buyer.request';
import { catchError, map, Observable } from 'rxjs';
import { Buyer } from './buyer.class';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { IBuyerDto } from './buyer.dto';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  constructor(
    private apiService: ApiService,
  ) {}

  get(
    id: number,
    options: IBuyerRequestOptions = {}
  ): Observable<Buyer | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.buyerGet}/${id}`;
    
    let params = new HttpParams();
    if (options.buyerIds && options.buyerIds.length) {
      params = params.set('idb', options.buyerIds.join(','));
    }          
    if (options.userIds && options.userIds.length) {
      params = params.set('idu', options.userIds.join(','));
    }
  
    return this.apiService.get<IBuyerDto>(url, { params }).pipe(
      map(dto => new Buyer(dto)),
      catchError(this.apiService.handleError.bind(this)) // Reusing the centralized error handler
    );
  }
  

  delete(idBuyer: number): Observable<void> {
    console.log('ici delete');
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.buyerDelete}/${idBuyer}`;
    console.log('Delete URL:', url);
  
    return this.apiService.delete<void>(url).pipe(
      catchError(this.apiService.handleError.bind(this)) // Centralized error handling
    );
  }  

  save(buyer: Buyer): Observable<Buyer | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.buyerSave}`;
    console.log(url);
  
    return this.apiService.post<IBuyerDto>(url, buyer.getDto()).pipe(
      map(dto => new Buyer(dto)),
      catchError(this.apiService.handleError.bind(this))
    );
  }
  

  list(options: IBuyerRequestOptions): Observable<Buyer[] | null> {
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.buyerList}`;
    let params = new HttpParams()
      .set('usr', options.loadUsers ? '1' : '0')
      .set('sts', options.loadSeats ? '1' : '0');
    
    if (options.buyerIds && options.buyerIds.length) {
      params = params.set('idb', options.buyerIds.join(','));
    }
    
    if (options.userIds && options.userIds.length) {
      params = params.set('idu', options.userIds.join(','));
    }
    
    console.log('Request URL:', url);
    console.log('Request Params:', params.toString());
  
    return this.apiService.get<IBuyerDto[]>(url, { params }).pipe(
      map(dtos => dtos.map(dto => new Buyer(dto))),
      catchError(this.apiService.handleError.bind(this))
    );
  }
  

  setSeatCount(buyer: Buyer): Observable<Buyer | null> {
    const dto: Partial<IBuyerDto> = {
      seatCount: buyer.seatCount,
    };
    
    const url: string = `${environment.api1.connexion.url}${environment.api2.routes.buyerUpdate}/${buyer.id}`;
    console.log('Request URL:', url);
  
    return this.apiService.patch<IBuyerDto>(url, dto).pipe(
      map(dto => new Buyer(dto)),
      catchError(this.apiService.handleError.bind(this))
    );
  }  

}
