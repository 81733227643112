<p>create works!</p>
<div class="user-creation-form">
    <h3>Créer un utilisateur</h3>
    <form (ngSubmit)="createUser()">
        <div class="form-group">
            <label for="username">Nom d'utilisateur</label>
            <input 
                type="text" 
                id="username" 
                [(ngModel)]="user.username" 
                name="username" 
                required
                placeholder="Entrez le nom d'utilisateur" />
        </div>

        <div class="form-group">
            <label for="email">Email</label>
            <input 
                type="email" 
                id="email" 
                [(ngModel)]="user.email" 
                name="email" 
                required
                placeholder="Entrez l'email" />
        </div>

        <div class="form-group">
            <label for="password">Mot de passe</label>
            <input 
                type="password" 
                id="password" 
                [(ngModel)]="user.password_deprecated" 
                name="password" 
                required
                placeholder="Entrez le mot de passe" />
        </div>

        <div class="form-group">
            <label for="confirmPassword">Confirmez le mot de passe</label>
            <input 
                type="password" 
                id="confirmPassword" 
                [(ngModel)]="confirmPassword" 
                name="confirmPassword" 
                required
                placeholder="Confirmez le mot de passe" />
        </div>

        <div class="form-actions">
            <button type="submit" [disabled]="!canSubmit()">Créer</button>
        </div>
    </form>
</div>
