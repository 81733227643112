import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ITableRequestOptions } from '../../table.dto';
import { Table } from '../../table.class';
import { TableService } from '../../table.service';
import { SeatListManagerPlugin } from 'src/app/room/seat/plugin/manager/seat-list-manager.plugin';

@Component({
  selector: 'plugin-table-list-manager',
  standalone: true,
  imports: [
    CommonModule,
    SeatListManagerPlugin,
  ],
  templateUrl: './table-list-manager.plugin.html',
  styleUrls: ['./table-list-manager.plugin.scss']
})
export class TableListManagerPlugin {
  private _options: ITableRequestOptions = {};
  @Input()
  set options(value: ITableRequestOptions) {
    this._options = value;
    this.load();
  }
  get options() {
    return this._options;
  }

  list: Table[] = [];

  constructor(
    private tableService: TableService,
  ) {}

  load() {
    this.tableService.list(this.options).subscribe(l => {
      console.log('table list manager plugin', l);
      if (l) {
        this.list = l;
        console.log(l);
      } else {
        console.error('erreur chargement liste');
      }
    })
  }
}
