import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Table } from 'src/app/room/table/table.class';
import { ITableDto } from 'src/app/room/table/table.dto';
import { ISeatRequestOptions } from '../../seat.request';
import { Seat } from '../../seat.class';
import { SeatService } from '../../seat.service';
import { SeatInlineManagerPlugin } from '../inline-manager/seat-inline-manager.plugin';

/**
 * reçoit les données d'une table et affiche la liste des sièges 
 * pour gestion
 */
@Component({
  selector: 'plugin-seat-list-manager',
  standalone: true,
  imports: [
    CommonModule,
    SeatInlineManagerPlugin,
  ],
  templateUrl: './seat-list-manager.plugin.html',
  styleUrls: ['./seat-list-manager.plugin.scss']
})
export class SeatListManagerPlugin {
  private _options: ISeatRequestOptions = {};
  @Input()
  set options(value: ISeatRequestOptions) {
    this._options = value;
    this.load();
  }
  get options(): ISeatRequestOptions {
    return this._options;
  }

  list: Seat[] = [];

  constructor(
    private seatService: SeatService,
  ) {}

  load() {
    this.options.loadBuyer = true;
    this.seatService.list(this.options).subscribe(l => {
      if (l) {
        this.list = l;
      } else {
        console.error('boouuhhh la liste !');
      }
    })
  };

  refresh() {
    this.options = {};
    this.options = this._options;
  }
}
